/* eslint-disable react/jsx-no-bind */
import { QuestionnaireSection, Option, Question } from "@teamprevent/common/questionnaire/models";

import { Questionnaire } from "@teamprevent/common/questionnaire/components/Questionnaire";

import { CustomQuestionConfig } from "@teamprevent/common/questionnaire/models";
import { TermsAndConditionsAcceptanceModal } from "@teamprevent/common/termsAndConditions/TermsAndConditionsAcceptanceModal";

import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Loader, Container, Button } from "semantic-ui-react";

import { requiredNumber } from "not-valid/bin/validators";

import { sraQuestionnaireApi } from "../SraQuestionnaireApi";

import { QuestionnaireConfigApi } from "../../questionnaireConfig/QuestionnaireConfigApi";
import { EmployeeSraSummary } from "../../sraSurveyResult/components/EmployeeSraSummary";

interface MatchParams {
    completionRequestId: string;
    customerId: string;
}

type SraQuestionnaireProps = RouteComponentProps<MatchParams>;

const SraQuestionnaireUnconnected: React.FunctionComponent<SraQuestionnaireProps> = (props) => {
    const frequencyOptions = [
        { text: "Never", value: 1 },
        { text: "Seldom", value: 2 },
        { text: "Sometimes", value: 3 },
        { text: "Often", value: 4 },
        { text: "Always", value: 5 }
    ];

    const agreementOptions = [
        { text: "Strongly disagree", value: 1 },
        { text: "Disagree", value: 2 },
        { text: "Neutral", value: 3 },
        { text: "Agree", value: 4 },
        { text: "Strongly agree", value: 5 }
    ];

    const createRadioButtonQuestion = (question: string, options: Option[]): { question: Question<number> } => ({
        question: {
            id: question,
            text: question,
            questionType: "radioButton", options, validators: [requiredNumber()]
        }
    });

    const section: QuestionnaireSection = {
        id: "sraQuestionnaire",
        title: "Stress Risk Assessment",
        questions: [
            createRadioButtonQuestion("I am clear what is expected of me at work", frequencyOptions),
            createRadioButtonQuestion("I can decide when to take a break", frequencyOptions),
            createRadioButtonQuestion("Different groups at work demand things from me that are hard to combine", frequencyOptions),
            createRadioButtonQuestion("I know how to go about getting my job done", frequencyOptions),
            createRadioButtonQuestion("I am subject to personal harassment in the form of unkind words or behaviour", frequencyOptions),
            createRadioButtonQuestion("I have unachievable deadlines", frequencyOptions),
            createRadioButtonQuestion("If work gets difficult, my colleagues will help me", frequencyOptions),
            createRadioButtonQuestion("I am given supportive feedback on the work I do", frequencyOptions),
            createRadioButtonQuestion("I have to work very intensively", frequencyOptions),
            createRadioButtonQuestion("I have a say in my own work speed", frequencyOptions),
            createRadioButtonQuestion("I am clear what my duties and responsibilities are", frequencyOptions),
            createRadioButtonQuestion("I have to neglect some tasks because I have too much to do", frequencyOptions),
            createRadioButtonQuestion("I am clear about the goals and objectives for my department", frequencyOptions),
            createRadioButtonQuestion("There is friction or anger between colleagues", frequencyOptions),
            createRadioButtonQuestion("I have a choice in deciding how I do my work", frequencyOptions),
            createRadioButtonQuestion("I am unable to take sufficient breaks", frequencyOptions),
            createRadioButtonQuestion("I understand how my work fits into the overall aim of the organisation", frequencyOptions),
            createRadioButtonQuestion("I am pressured to work long hours", frequencyOptions),
            createRadioButtonQuestion("I have a choice in deciding what I do at work", frequencyOptions),
            createRadioButtonQuestion("I have to work very fast", frequencyOptions),
            createRadioButtonQuestion("I am subject to bullying at work", frequencyOptions),
            createRadioButtonQuestion("I have unrealistic time pressures", frequencyOptions),
            createRadioButtonQuestion("I can rely on my line manager to help me out with a work problem", frequencyOptions),
            createRadioButtonQuestion("I get help and support I need from colleagues", agreementOptions),
            createRadioButtonQuestion("I have some say over the way I work", agreementOptions),
            createRadioButtonQuestion("I have sufficient opportunities to question managers about change at work", agreementOptions),
            createRadioButtonQuestion("I receive the respect at work I deserve from my colleagues", agreementOptions),
            createRadioButtonQuestion("Staff are always consulted about change at work", agreementOptions),
            createRadioButtonQuestion("I can talk to my line manager about something that has upset or annoyed me about work", agreementOptions),
            createRadioButtonQuestion("My working time can be flexible", agreementOptions),
            createRadioButtonQuestion("My colleagues are willing to listen to my work-related problems", agreementOptions),
            createRadioButtonQuestion("When changes are made at work, I am clear how they will work out in practice", agreementOptions),
            createRadioButtonQuestion("I am supported through emotionally demanding work", agreementOptions),
            createRadioButtonQuestion("Relationships at work are strained", agreementOptions),
            createRadioButtonQuestion("My line manager encourages me at work", agreementOptions)
        ]
    };

    const { customerId, completionRequestId } = props.match.params;
    const [validCompletionRequest, setValidCompletionRequest] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [consumed, setConsumed] = React.useState(false);
    const [questionnaireSubmitted, setQuestionnaireSubmitted] = React.useState(false);
    const [sections, setSections] = React.useState([section]);
    const complete = sections.every(s => s.questions.every(q => q.validationErrors && q.validationErrors.length === 0));
    const [termsAndConditionsAcceptanceModalOpen, setTermsAndConditionsAcceptanceModalOpen] = React.useState(false);
    const [termsAndConditionsAcceptanceModalShown, setTermsAndConditionsAcceptanceModalShown] = React.useState(false);
    const [termsContent, setTermsContent] = React.useState<string>();
    const [accepted, setAccepted] = React.useState(false);
    const [submittedSuccessfully, setSubmittedSuccessfully] = React.useState<boolean>(false);

    React.useEffect(() => {
        (async () => {
            const result = await sraQuestionnaireApi.validCompletionRequest(customerId, completionRequestId);
            setConsumed(result === "Consumed");
            setValidCompletionRequest(result === "Valid");

            const termsAcceptanceConfig = await QuestionnaireConfigApi.get("termsAndConditionsOfUse", customerId);
            if (termsAcceptanceConfig && termsAcceptanceConfig.questions?.length > 0) {
                const consentText = (termsAcceptanceConfig.questions[0] as CustomQuestionConfig).questionText;
                setTermsContent(consentText);
                setTermsAndConditionsAcceptanceModalOpen(true);
                setTermsAndConditionsAcceptanceModalShown(true);
            }

            setLoading(false);
        })();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onSubmitQuestionnaire = async () => {
        setQuestionnaireSubmitted(true);
        const result = await sraQuestionnaireApi.submitSraQuestionnaire(customerId, completionRequestId, sections, accepted);
        setSubmittedSuccessfully(result);
    };

    const onAccept = (acceptedParam: boolean) => {
        setAccepted(acceptedParam);
    };

    function onModalClose() {
        setTermsAndConditionsAcceptanceModalOpen(false);
    }

    if (submittedSuccessfully) {
        return (
            <EmployeeSraSummary
                customerId={props.match.params.customerId}
                completionRequestId={props.match.params.completionRequestId}
                skipTermsAcceptance={true}
            />
        );
    }

    if (loading === true) {
        return (<Loader active={loading}>Loading</Loader>);
    }

    if (consumed === true) {
        return (<Container>This questionnaire has already been completed.</Container>);
    }

    if (validCompletionRequest === false) {
        return (<Container>This questionnaire was not found.</Container>);
    }

    if (complete === true) {
        return (
            <Container textAlign="center">
                <Button onClick={onSubmitQuestionnaire} loading={questionnaireSubmitted} disabled={questionnaireSubmitted} content="Submit" />
            </Container>
        );
    }

    if (!accepted && termsAndConditionsAcceptanceModalOpen) {
        return (
            <TermsAndConditionsAcceptanceModal
                open={termsAndConditionsAcceptanceModalOpen}
                onClose={onModalClose}
                onAccept={onAccept}
                termsContent={termsContent}
            />
        );
    }

    if (!accepted && !termsAndConditionsAcceptanceModalOpen && termsAndConditionsAcceptanceModalShown) {
        return (<Container>You need to accept terms and conditions to proceed.
            <br />Please load the questionnaire again to read and accept the terms and conditions.</Container>);
    }

    return (
        <Container>
            <Questionnaire sections={sections} onAnswersChanged={setSections} displaySingleQuestion />
        </Container>
    );
};

export const SraQuestionnaire = withRouter(SraQuestionnaireUnconnected);
