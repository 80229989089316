import axios from "axios";
import { QuestionConfig, CustomQuestionConfig } from "@teamprevent/common/questionnaire/models";

export const QuestionnaireConfigApi = {
    get: async (questionnaireType: string, customerId: string) => {
        const response = await axios.get<{ questions: QuestionConfig[]; additionalQuestions: CustomQuestionConfig[] }>(
            `/api/questionnaireconfig/${questionnaireType}/customer/${customerId}`
        );
        return response.data;
    }
};
