/* eslint-disable react/jsx-no-bind */
import { VisualSummary } from "@teamprevent/common/sraVisualSummary/components/VisualSummary";
import { SraQuestionnaireResultModel } from "@teamprevent/common/sraVisualSummary/models";

import * as React from "react";
import { Container, Loader, Header, Grid, Button } from "semantic-ui-react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { mean } from "lodash";

import { CustomQuestionConfig } from "@teamprevent/common/questionnaire/models";
import { TermsAndConditionsAcceptanceModal } from "@teamprevent/common/termsAndConditions/TermsAndConditionsAcceptanceModal";

import { EmployeeSraQuestionnaireResultModel } from "../models";
import { sraSurveyResultApi } from "../sraSurveyResultApi";

import { QuestionnaireConfigApi } from "../../questionnaireConfig/QuestionnaireConfigApi";

import "../styles/sra-summary.scss";

interface EmployeeSraSummaryMatchParams {
    customerId: string;
    completionRequestId: string;
}

interface EmployeeSraSummaryProps {
    customerId?: string;
    completionRequestId?: string;
    skipTermsAcceptance?: boolean;
}

type Props = EmployeeSraSummaryProps & RouteComponentProps<EmployeeSraSummaryMatchParams>;

const EmployeeSraSummaryUnconnected: React.FunctionComponent<Props> = (props) => {

    const { customerId, completionRequestId } = props.match.params;
    const customerIdToUse = props.customerId ?? customerId;
    const completionRequestIdToUse = props.completionRequestId ?? completionRequestId;
    const [loading, setLoading] = React.useState(true);
    const [questionnaireResult, setQuestionnaireResult] = React.useState<Partial<EmployeeSraQuestionnaireResultModel>>({});
    const [termsAndConditionsAcceptanceModalOpen, setTermsAndConditionsAcceptanceModalOpen] = React.useState(false);
    const [termsAndConditionsAcceptanceModalShown, setTermsAndConditionsAcceptanceModalShown] = React.useState(false);
    const [termsContent, setTermsContent] = React.useState<string>();
    const [accepted, setAccepted] = React.useState(false);

    React.useEffect(() => {
        (async () => {
            const result = await sraSurveyResultApi.getQuestionnaireResult(customerIdToUse, completionRequestIdToUse);

            if (result !== null && result.questionnaireScores !== null) {
                result.overallScore = mean(result.questionnaireScores.map(q => q.meanScore));
            }

            setQuestionnaireResult(result);

            if (!props.skipTermsAcceptance) {
                const termsAcceptanceConfig = await QuestionnaireConfigApi.get("termsAndConditionsOfUse", customerIdToUse);
                if (termsAcceptanceConfig && termsAcceptanceConfig.questions?.length > 0) {
                    const consentText = (termsAcceptanceConfig.questions[0] as CustomQuestionConfig).questionText;
                    setTermsContent(consentText);
                    setTermsAndConditionsAcceptanceModalOpen(true);
                    setTermsAndConditionsAcceptanceModalShown(true);
                }
            }

            setLoading(false);
        })();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if (loading === true) {
        return (<Loader active>Loading</Loader>);
    }

    if (questionnaireResult === null) {
        return (<Container>Survey result was not found</Container>);
    }

    const onClick = () => {
        window.print();
    };

    const onAccept = (acceptedParam: boolean) => {
        setAccepted(acceptedParam);
    };

    function onModalClose() {
        setTermsAndConditionsAcceptanceModalOpen(false);
    }

    if (!accepted && termsAndConditionsAcceptanceModalOpen) {
        return (
            <TermsAndConditionsAcceptanceModal
                open={termsAndConditionsAcceptanceModalOpen}
                onClose={onModalClose}
                onAccept={onAccept}
                termsContent={termsContent}
            />
        );
    }

    if (!accepted && !termsAndConditionsAcceptanceModalOpen && termsAndConditionsAcceptanceModalShown) {
        return (<Container>You need to accept terms and conditions to proceed.
            <br />Please load the questionnaire again to read and accept the terms and conditions.</Container>);
    }

    return (
        <Container className="sra-summary-container">
            <div className="print-button">
                <Button onClick={onClick} size="big" content="Print" />
            </div>
            <Header as="h2" textAlign="center">Stress Risk Assessment Summary</Header>
            <Grid columns={3}>
                <Grid.Row>
                    <Grid.Column>
                        {questionnaireResult.caseId &&
                            <Header as="h4" textAlign="left">Case ID: {questionnaireResult.caseId}</Header>}
                    </Grid.Column>
                    <Grid.Column></Grid.Column>
                    <Grid.Column>
                        {questionnaireResult.dateCompleted &&
                            <Header as="h4" textAlign="right">Date Completed: {questionnaireResult.dateCompleted.format("DD.MM.YYYY")}</Header>}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <div className="summary-text-container">
                {
                    questionnaireResult.expired
                        ? <p className="summary-paragraph">Your results can only be viewed for a maximum of 15 days, which have now expired.</p>
                        : <VisualSummary questionnaireResult={questionnaireResult as SraQuestionnaireResultModel} />
                }
            </div>

        </Container>
    );
};

export const EmployeeSraSummary = withRouter(EmployeeSraSummaryUnconnected);
