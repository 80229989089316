import * as React from "react";

import "../styles/progress-ring.scss";
import { CategoryType } from "./SmileyTray";

interface ProgressRingProps {
    score: number;
    color: string;
    label: string;
    type: CategoryType;
}

export const ProgressRing: React.FunctionComponent<ProgressRingProps> = (props) => {

    const containerSize = 310;
    const strokeWidth = containerSize / 4;
    const radius = (containerSize / 2) - (strokeWidth / 2);
    const [cx, cy] = [containerSize / 2, containerSize / 2];
    const circumference = 2 * Math.PI * radius;

    return (
        <div className="progress-ring-container">
            <svg
                className="progress-ring"
                width={containerSize}
                height={containerSize}
            >
                <circle
                    strokeWidth={strokeWidth}
                    stroke="rgb(230, 231, 232)"
                    fill="transparent"
                    r={radius}
                    cx={cx}
                    cy={cy}
                />
                <circle
                    /* eslint-disable react/forbid-dom-props */
                    /* eslint-reason dynamically assigned styles based on score */
                    style={{
                        strokeDasharray: `${circumference} ${circumference}`,
                        strokeDashoffset: `${circumference * (1 - props.score / 5)}`,
                        transition: "0.35s",
                        transform: "rotate(-90deg)",
                        transformOrigin: "50% 50%",
                        transitionTimingFunction: "ease-in-out",
                    }}
                    /* eslint-enable react/forbid-dom-props */
                    strokeWidth={strokeWidth}
                    stroke={props.color}
                    fill="transparent"
                    r={radius}
                    cx={cx}
                    cy={cy}
                />
                <text x="50%" y="45%" textAnchor="middle" dominantBaseline="middle" fontSize="120%">{props.label}</text>
                <text x="50%" y="55%" textAnchor="middle" dominantBaseline="middle" fontSize="180%" fontWeight="bold">{props.score.toFixed(1)}</text>
            </svg>
        </div>
    );
};
