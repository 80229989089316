import * as React from "react";
import { Progress } from "semantic-ui-react";
import { flatten } from "lodash";

import { QuestionnaireSection } from "../models";

export const QuestionnaireProgressIndicator: React.FunctionComponent<{ sections: QuestionnaireSection[] }> = (props) => {
    const questions = flatten(props.sections.map(s => s.questions));

    return (
        <Progress
            className="progress"
            total={questions.length}
            value={questions.filter(q => q.validationErrors && q.validationErrors.length === 0).length}
            indicating
        />
    );
};
