import {
    BrowserRouter as Router,
    Route
} from "react-router-dom";

import * as React from "react";

import { SraQuestionnaire } from "./sraQuestionnaire";
import { Home } from "./home";

import { EmployeeSraSummary } from "./sraSurveyResult/components/EmployeeSraSummary";
import { Header } from "./global";
import { Footer } from "./global";

export const App = () => (
    <>
        <Header />
        <div className="app-container">
            <Router>
                <Route exact path="/" component={Home} />
                <Route exact path="/customer/:customerId/stressriskassessment/:completionRequestId" component={SraQuestionnaire} />
                <Route exact path="/customer/:customerId/stressriskresults/:completionRequestId" component={EmployeeSraSummary} />
            </Router>
        </div>
        <Footer />
    </>);
