import * as React from "react";
import { Menu, Container } from "semantic-ui-react";

import { PrivacyAndCookiesMenu } from "@teamprevent/common/header";

export function Footer() {
    return (
        <>
            <Container>
                <Menu secondary>
                    <Menu.Item position="right">
                        <PrivacyAndCookiesMenu />
                    </Menu.Item>
                </Menu>
            </Container>
        </>
    );
}
