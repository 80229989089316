import * as React from "react";

import { QuestionProps } from "./Question";

export const TextQuestion: React.FunctionComponent<QuestionProps> = (props) => {
    const onAnswerChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.onAnswerChanged(event.target.value);
    };

    return (
        // eslint-disable-next-line react/jsx-no-bind
        <input value={props.answer || ""} onChange={onAnswerChanged} onBlur={props.onDirty}></input>
    );
};
