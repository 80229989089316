import * as React from "react";
import { Container, Grid, Menu } from "semantic-ui-react";

export const Header: React.FunctionComponent = () => (
    <>
        <Container>
            <Grid container padded columns="equal" verticalAlign="bottom" className="site-header">
                <Grid.Row>
                    <Grid.Column>
                        <img height="30" className="site-logo" src="/assets/myoh_Logo_wellbeing_RGB_Pos.svg" />
                    </Grid.Column>
                    <Grid.Column only="tablet computer" textAlign="right">
                        <img height="45" className="tp-logo" src="/assets/Optima_Health_Logo.svg" />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
        <Menu inverted />
    </>
);
