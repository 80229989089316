import * as React from "react";

import "../styles/smiley-tray.scss";

import { QuestionnaireGroupScore } from "../models";

import { getColorForMeanScore } from "./VisualSummary";
import { SmileyButton } from "./SmileyButton";

export type ValidSmileColors = "Green" | "Blue" | "Yellow" | "Red";
export type CategoryType = "demands" | "control" | "managersSupport" | "peerSupport" | "relationships" | "role" | "change" | "overall";

interface SmileyButtonConfig {
    label: string;
    type: CategoryType;
}

interface SmileyTrayProps {
    onClick: (color: ValidSmileColors, label: string, type: CategoryType) => void;
    smileyButtonsConfig: SmileyButtonConfig[];
    scores: QuestionnaireGroupScore[];
    overallScore: number;
}

export const SmileyTray: React.FunctionComponent<SmileyTrayProps> = (props) => {
    return (
        <div className="smiley-container">
            {props.smileyButtonsConfig.map(c => {
                const entry = props.scores.find(s => s.grouping === c.type);
                const meanScore = entry ? entry.meanScore : props.overallScore;
                return (
                    <SmileyButton
                        key={c.label}
                        label={c.label}
                        color={getColorForMeanScore(c.type, meanScore)}
                        // eslint-disable-next-line react/jsx-no-bind
                        onClick={() => props.onClick(getColorForMeanScore(c.type, meanScore), c.label, c.type)}
                    />
                );
            })}
        </div>
    );
};

