import * as React from "react";
import { Dropdown } from "semantic-ui-react";

const navigate = (href: string) => () => {
    window.location.href = href;
};

// Turns the entire clickable area of a dropdown item into a link.
// NB use only for links not handled by the redux router (e.g. login/logout).
const DropdownAnchor: React.SFC<{ href: string }> = ({ href, children }) => (
    <Dropdown.Item onClick={navigate(href)}>
        <a href={href}>{children}</a>
    </Dropdown.Item>
);

export { DropdownAnchor };
