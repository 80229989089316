import * as React from "react";
import * as ReactDOM from "react-dom";

import "semantic-ui-less/semantic.less";
import "./styles/index.scss";
import { App } from "./app";

ReactDOM.render(
    <App />,
    document.getElementById("approot")
);
