/* eslint-disable react/jsx-no-bind */
import * as React from "react";
import { Modal, Button, Checkbox, CheckboxProps } from "semantic-ui-react";

interface OwnProps {
    open: boolean;
    onClose: () => void;
    onAccept: (accepted: boolean) => void;
    termsContent: string;
}

export const TermsAndConditionsAcceptanceModal: React.FunctionComponent<OwnProps> = props => {
    const [accepted, setAccepted] = React.useState(false);

    const onAccept = () => {
        props.onAccept(accepted);
        props.onClose();
    };

    function onAcceptedChanged(p: React.FormEvent<HTMLInputElement>, d: CheckboxProps) {
        setAccepted(d.checked);
    }

    return (
        <Modal open={props.open}>
            <Modal.Header>
                Optima Health System Terms and Conditions of Use
            </Modal.Header>
            <Modal.Content>
                <span>
                    <div dangerouslySetInnerHTML={{ __html: props.termsContent }} />
                    <br />
                    <strong>Please tick to confirm your agreement to the above Terms and Conditions of Use</strong>
                    &nbsp;<Checkbox checked={accepted} onChange={onAcceptedChanged} />
                </span>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    primary
                    onClick={onAccept}
                >
                    Close
                </Button>
            </Modal.Actions>
        </Modal>
    );
};
