import * as React from "react";

import { validate } from "not-valid";

import { Container, Header, Segment } from "semantic-ui-react";

import { QuestionnaireSection as QuestionnaireSectionModel, AnsweredQuestion, Answer } from "../models";

import { QuestionnaireSection } from "./QuestionnaireSection";

import { QuestionnaireProgressIndicator } from "./QuestionnaireProgressIndicator";

interface Props {
    sections: QuestionnaireSectionModel[];
    collapsible?: boolean;
    displaySingleQuestion?: boolean;
    onAnswersChanged: (sections: QuestionnaireSectionModel[]) => void;
}

export const Questionnaire: React.FunctionComponent<Props> = (props) => {
    React.useEffect(() => {
        (async () => {
            const validatedQuestions = await Promise.all(props.sections.map(await (async s => ({
                ...s,
                questions: await Promise.all(s.questions.map(async q => {
                    const validationErrors = await validate(q.question.validators || [], q.answer);
                    return { ...q, validationErrors };
                }))
            }))));
            props.onAnswersChanged(validatedQuestions);
        })();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const [expandedSections, setExpandedSections] = React.useState([]);

    const updateAnswer = async (answeredQuestion: AnsweredQuestion<Answer>) => {
        const validationErrors = await validate(answeredQuestion.question.validators || [], answeredQuestion.answer);
        const validatedAnsweredQuestion = { ...answeredQuestion, validationErrors };
        const updatedSections = props.sections.map(s =>
            ({ ...s, questions: s.questions.map(q => q.question.id === validatedAnsweredQuestion.question.id ? validatedAnsweredQuestion : q) }));
        props.onAnswersChanged(updatedSections);
    };

    const toggleSection = (id: string) => () =>
    {
        setExpandedSections((es) => {
            if (es.some(s => s === id)) {
                return es.filter(s => s !== id);
            }

            return [...es, id];
        });
    };

    return (
        <Container className="questionnaire">
            <Header as="h1">Stress Assessment Questionnaire</Header>
            <QuestionnaireProgressIndicator sections={props.sections} />
            <Segment.Group>
                {props.sections.map(s => (
                    <QuestionnaireSection
                        {...s}
                        questions={s.questions}
                        onAnswerChanged={updateAnswer} // eslint-disable-line react/jsx-no-bind
                        expanded={props.collapsible ? expandedSections.some(es => es === s.id) : true}
                        onHeaderClicked={props.collapsible ? toggleSection(s.id) : null}
                        displaySingleQuestion={props.displaySingleQuestion}
                        key={s.id}
                    />
                ))}
            </Segment.Group>
        </Container>
    );
};
