import * as React from "react";
import { Dropdown } from "semantic-ui-react";

import { DropdownAnchor } from ".";

const PrivacyAndCookiesMenu: React.FunctionComponent = () => (
    <Dropdown inline item upward className="cookies-dropdown" text="Privacy &amp; Cookies">
        <Dropdown.Menu>
            <DropdownAnchor href="https://tphealth.co.uk/privacy-statement">Privacy Policy</DropdownAnchor>
            <DropdownAnchor href="https://tphealth.co.uk/cookie-policy">Cookies Policy</DropdownAnchor>
        </Dropdown.Menu>
    </Dropdown>
);

export { PrivacyAndCookiesMenu };
