import * as React from "react";

import { SraQuestionnaireResultModel } from "../models";

import { ProgressRing } from "./ProgressRing";
import { SmileyButton } from "./SmileyButton";
import { SmileyTray, ValidSmileColors, CategoryType } from "./SmileyTray";

import "../styles/visual-summary.scss";

interface CurrentSelection {
    score: number;
    color: ValidSmileColors;
    label: string;
    type: CategoryType;
    advice: string;
}

const colors: { [key: string]: string } = {
    Green: "rgb(155, 199, 107)",
    Blue: "rgb(24, 199, 249)",
    Yellow: "rgb(253, 207, 85)",
    Red: "rgb(235, 94, 85)"
};

const getPercentileBoundsForGrouping: {
    [key: string]: {
        twentiethPercentile: number;
        fiftiethPercentile: number;
        eightiethPercentile: number;
    };
} = {
    demands: { twentiethPercentile: 2.875, fiftiethPercentile: 3.5, eightiethPercentile: 4.25 },
    control: { twentiethPercentile: 2.666667, fiftiethPercentile: 3.5, eightiethPercentile: 4.333333 },
    managersSupport: { twentiethPercentile: 3, fiftiethPercentile: 3.8, eightiethPercentile: 4.6 },
    peerSupport: { twentiethPercentile: 3.333333, fiftiethPercentile: 4, eightiethPercentile: 4.75 },
    relationships: { twentiethPercentile: 3.75, fiftiethPercentile: 4.25, eightiethPercentile: 4.75 },
    role: { twentiethPercentile: 4.4, fiftiethPercentile: 5, eightiethPercentile: 5 },
    change: { twentiethPercentile: 2.666667, fiftiethPercentile: 3.666667, eightiethPercentile: 4 },
    overall: { twentiethPercentile: 2.666667, fiftiethPercentile: 3.666667, eightiethPercentile: 4 }

};

const getColorForBounds = (
    meanScore: number,
    type: CategoryType,
    bounds: {
        twentiethPercentile: number;
        fiftiethPercentile: number;
        eightiethPercentile: number;
    }) => {
    if (meanScore < bounds.twentiethPercentile) {
        return "Red";
    } else if (meanScore >= bounds.twentiethPercentile && meanScore < bounds.fiftiethPercentile) {
        return "Yellow";
    } else if (meanScore >= bounds.fiftiethPercentile && meanScore < bounds.eightiethPercentile) {
        return "Blue";
    } else if (meanScore >= bounds.eightiethPercentile) {
        return "Green";
    }
    return null;
};

export const getColorForMeanScore = (type: CategoryType, meanScore: number) =>
    getColorForBounds(
        meanScore,
        type,
        getPercentileBoundsForGrouping[type]
    );

interface VisualSummaryProps {
    questionnaireResult: SraQuestionnaireResultModel;
}

interface SmileyButtonConfig {
    label: string;
    type: CategoryType;
}

const overallSmileyButtonConfig: SmileyButtonConfig = { label: "Overall", type: "overall" };
const smileyButtonsConfig: SmileyButtonConfig[] = [
    { label: "Demands", type: "demands" },
    { label: "Control", type: "control" },
    { label: "Managers Support", type: "managersSupport" },
    { label: "Peer Support", type: "peerSupport" },
    { label: "Relationships", type: "relationships" },
    { label: "Role", type: "role" },
    { label: "Change", type: "change" },
];
const allSmileyButtonsConfig = [...smileyButtonsConfig, overallSmileyButtonConfig];

export const VisualSummary: React.FunctionComponent<VisualSummaryProps> = (props) => {

    const [selection, setSelection] = React.useState<CurrentSelection>({
        score: 0,
        color: "Blue",
        label: "Overall",
        type: "overall",
        advice: ""
    });

    const onClick = (color: ValidSmileColors, label: string, type: CategoryType) => {
        const questionGroupScore = props.questionnaireResult.questionnaireScores.find(q => q.grouping === type);
        const score = questionGroupScore ? questionGroupScore.meanScore : props.questionnaireResult.overallScore;
        const advice = questionGroupScore ? questionGroupScore.advice : "";
        setSelection({
            score, color, label, type, advice
        });
    };

    React.useEffect(() => {
        (async () => {
            if (props.questionnaireResult !== null) {
                setSelection({
                    score: props.questionnaireResult.overallScore,
                    color: "Blue",
                    label: "Overall",
                    type: "overall",
                    advice: ""
                });
            }
        })();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <div className="react-print">
                <ProgressRing
                    label={overallSmileyButtonConfig.label}
                    type={overallSmileyButtonConfig.type}
                    score={props.questionnaireResult.overallScore}
                    color={colors[getColorForMeanScore(overallSmileyButtonConfig.type, props.questionnaireResult.overallScore)]}
                />
                {smileyButtonsConfig.map(smileyButton => {
                    const questionnaireScore = props.questionnaireResult.questionnaireScores.find(score => score.grouping === smileyButton.type);
                    return (
                        <div key={questionnaireScore.grouping} className="print-summary-block">
                            <SmileyButton
                                label={smileyButton.label}
                                meanScore={questionnaireScore.meanScore}
                                color={getColorForMeanScore(smileyButton.type, questionnaireScore.meanScore)}
                            />
                            <div className="advice" dangerouslySetInnerHTML={{ __html: questionnaireScore.advice }} />
                        </div>
                    );
                })}
            </div>

            <div className="react-no-print">
                <div className="summary-visual-container">
                    <ProgressRing
                        label={selection.label}
                        type={selection.type}
                        score={selection.score}
                        color={colors[getColorForMeanScore(selection.type, selection.score)]}
                    />
                    <SmileyTray
                        onClick={onClick} // eslint-disable-line react/jsx-no-bind
                        scores={props.questionnaireResult.questionnaireScores}
                        overallScore={props.questionnaireResult.overallScore}
                        smileyButtonsConfig={allSmileyButtonsConfig}
                    />
                </div>

                {(selection && selection.advice)
                    ? (
                        <div className="advice" dangerouslySetInnerHTML={{ __html: selection.advice }} />
                    )
                    : (
                        <h3 className="center">
                            Please click on each individual face to see the results for that standard
                        </h3>
                    )
                }
            </div>

            <p className="summary-paragraph">
                {/* eslint-disable-next-line max-len */}
                    You can only access the information through this link for 15 days. After 15 days you will be unable to retrieve the information so please ensure you use the print functionality to print a copy to PDF for you to retain.
            </p>
        </>
    );
};
