import { Form, Label, Header } from "semantic-ui-react";

import * as React from "react";

import { AnsweredQuestion, Answer } from "../models";

import { TextQuestion } from "./TextQuestion";
import { RadioButtonQuestion } from "./RadioButtonQuestion";

type Props = AnsweredQuestion<Answer> & { onAnswerChanged: (answer: Answer) => void; displaySingleQuestion?: boolean };

export interface QuestionProps {
    answer: string;
    onAnswerChanged: (answer: Answer) => void;
    onDirty: () => void;
}

export const Question: React.FunctionComponent<Props> = (props) => {
    const [dirty, setDirty] = React.useState<boolean>(false);

    const onDirty = () => setDirty(true);

    const renderQuestion = () => {
        const questionProps = {
            answer: props.answer as string,
            onAnswerChanged: props.onAnswerChanged,
            onDirty
        };
        switch (props.question.questionType) {
            case "text":
                return <TextQuestion {...questionProps} />;
            case "radioButton":
                return <RadioButtonQuestion {...questionProps} options={props.question.options} />;
            default:
                throw new Error("Invalid question type");
        }
    };

    if (props.question.hidden && props.question.hidden()) {
        return null;
    }

    const validationErrors = props.validationErrors || [];
    return (
        <>
            <Form.Field error={dirty && validationErrors.length > 0}>
                <Header as="h4" className={props.displaySingleQuestion ? "single-question question-header" : ""}>{props.question.text}</Header>
                {renderQuestion()}
                {dirty && validationErrors.map((e, index) => <Label key={index} basic color="red" pointing>{e}</Label>)}
            </Form.Field>
        </>
    );
};
