import * as React from "react";

import { Form, Header, Icon } from "semantic-ui-react";

import { Question as QuestionModel, Answer, AnsweredQuestion } from "../models";

import { Question } from "./Question";

interface Props {
    id: string;
    title: string;
    questions: AnsweredQuestion<Answer>[];
    onAnswerChanged: (question: AnsweredQuestion<Answer>) => void;
    onHeaderClicked: () => void;
    expanded: boolean;
    displaySingleQuestion?: boolean;
}

export const QuestionnaireSection: React.FunctionComponent<Props> = (props) => {
    const updateAnswer = async (question: QuestionModel<Answer>, answer: Answer) => {
        const answeredQuestion: AnsweredQuestion<Answer> = { question, answer, validationErrors: [] };
        props.onAnswerChanged(answeredQuestion);
    };

    const onAnswerChanged = (question: QuestionModel<Answer>) => {
        return (answer: string) => updateAnswer(question, answer);
    };

    const isValid = props.questions.every(q => !q.validationErrors || q.validationErrors.length === 0);

    return (
        <>
            {!props.displaySingleQuestion &&
                <Header as="h5" attached="top" block onClick={props.onHeaderClicked}>
                    <Icon name={isValid ? "check circle" : "times circle"} color={isValid ? "green" : "red"} />
                    <Header.Content>{props.title}</Header.Content>
                </Header>
            }
            {props.expanded &&
                <Form>
                    {(props.displaySingleQuestion ? [props.questions.find(q => q.answer === null || q.answer === undefined)] : props.questions).map(q => (
                        q &&
                        <Question
                            key={q.question.id}
                            {...q}
                            answer={q.answer as string}
                            onAnswerChanged={onAnswerChanged(q.question)}
                            displaySingleQuestion
                        />
                    ))}
                </Form>
            }
        </>
    );
};
