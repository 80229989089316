import * as React from "react";

import { Form } from "semantic-ui-react";

import { Option, Answer } from "../models";

import { QuestionProps } from "./Question";

export const RadioButtonQuestion: React.FunctionComponent<QuestionProps & { options: Option[] }> = (props) => {
    const onAnswerChanged = (value: Answer) => () => {
        props.onAnswerChanged(value);
        props.onDirty();
    };

    return (
        <Form.Group widths="equal" className="questions-container">
            {props.options.map(o =>
                <Form.Button className="option-button" fluid basic key={o.value} content={o.text} onClick={onAnswerChanged(o.value)} type="button" />
            )}
        </Form.Group>
    );
};
