import axios, { AxiosError } from "axios";

import moment = require("moment");

import { EmployeeSraQuestionnaireResultModel } from "./models";

class SraSurveyResultApi {
    private readonly customerApiUrl = "/api/customer";
    private readonly sraSurveyResultApiUrl = "srasurveyresult";

    public async getQuestionnaireResult(customerId: string, completionRequestId: string): Promise<EmployeeSraQuestionnaireResultModel> {
        try {
            const response = await axios.get<EmployeeSraQuestionnaireResultModel>(
                `${this.customerApiUrl}/${customerId}/${this.sraSurveyResultApiUrl}/${completionRequestId}`);
            return this.parseEmployeeSraQuestionnaireResultModel(response.data);
        } catch (exception) {
            const axiosError = exception as AxiosError<string>;
            if (axiosError.response && axiosError.response.status && axiosError.response.status === 404) {
                return null;
            }

            throw axiosError;
        }
    }

    private parseEmployeeSraQuestionnaireResultModel(model: EmployeeSraQuestionnaireResultModel): EmployeeSraQuestionnaireResultModel {
        return { ...model, dateCompleted: model.dateCompleted && moment(model.dateCompleted) };
    }
}

export const sraSurveyResultApi = new SraSurveyResultApi();
