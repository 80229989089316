import { QuestionnaireSectionDto, QuestionnaireSection, AnsweredQuestionDto } from "@teamprevent/common/questionnaire/models";

import axios, { AxiosError } from "axios";

type CompletionRequestState = "NotFound" | "Consumed" | "Valid";

class SraQuestionnaireApi {
    private readonly customerApiUrl = "/api/customer";
    private readonly sraQuestionnaireApiUrl = "stressriskassessment";

    public async submitSraQuestionnaire(
        customerId: string,
        completionRequestId: string,
        questionnaireSection: QuestionnaireSection[],
        accepted: boolean): Promise<boolean> {
        try {
            const questionnaireSectionDto = questionnaireSection.map((s): QuestionnaireSectionDto => ({
                id: s.id,
                title: s.title,
                questions: s.questions.map((q): AnsweredQuestionDto => ({
                    id: q.question.id,
                    text: q.question.text,
                    answer: q.answer,
                    optionText: q.question.options.find(o => o.value === q.answer).text
                })),
            }));

            const result = await axios.post(
                `${this.customerApiUrl}/${customerId}/${this.sraQuestionnaireApiUrl}/${completionRequestId}/${accepted}`, questionnaireSectionDto);
            return result.status === 200;
        } catch (exception) {
            const axiosError = exception as AxiosError<string>;
            if (axiosError.response && axiosError.response.status && axiosError.response.status === 400) {
                // TODO(Stephen): trigger a toaster error
            }
            throw axiosError;
        }
    }

    public async validCompletionRequest(customerId: string, sraCompletionRequestId: string): Promise<CompletionRequestState> {
        try {
            await axios.get(`${this.customerApiUrl}/${customerId}/${this.sraQuestionnaireApiUrl}/${sraCompletionRequestId}`);
            return "Valid";
        } catch (exception) {
            const axiosError = exception as AxiosError<string>;
            if (axiosError.response && axiosError.response.status && axiosError.response.status === 404) {
                return "NotFound";
            }
            if (axiosError.response && axiosError.response.status && axiosError.response.status === 400) {
                return "Consumed";
            }
            throw axiosError;
        }
    }
}

export const sraQuestionnaireApi = new SraQuestionnaireApi();
