import * as React from "react";

import "../styles/smiley-button.scss";

import { ValidSmileColors } from "./SmileyTray";

interface SmileyButtonProps {
    color: ValidSmileColors;
    label: string;
    meanScore?: number;
    onClick?: () => void;
}

export const SmileyButton: React.FunctionComponent<SmileyButtonProps> = (props) => {
    return (
        <div
            className="smiley-button"
            onClick={props.onClick}
        >
            <img className="smiley-image" src={`/assets/${props.color.toLowerCase()}-smiley.svg`} />
            <p className="smiley-label"> {props.meanScore
                ? `${props.label} [${props.meanScore.toFixed(1)}]`
                : props.label}
            </p>
        </div>
    );
};
